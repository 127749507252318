<template lang="pug">
.Autenticate.pt-0
  .Autenticate__content
    Modal(v-if="showErrorExced" :hideHeader="false")
      template(#header)
        svg(width='23' height='24' viewbox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M0.85 12.2422C0.85 6.36051 5.61792 1.59244 11.4995 1.59219C17.3791 1.59871 22.1438 6.36355 22.15 12.2431C22.1495 18.1245 17.3815 22.8922 11.5 22.8922C5.61817 22.8922 0.85 18.124 0.85 12.2422Z' stroke='#F4F4F4' stroke-width='1.7')
          path(d='M11.5013 7.45312C10.9558 7.45312 10.543 7.74005 10.543 8.20351V13.4127C10.543 13.8766 10.9557 14.1626 11.5013 14.1626C12.0335 14.1626 12.4596 13.8646 12.4596 13.4127V8.20351C12.4596 7.75106 12.0335 7.45312 11.5013 7.45312ZM11.5013 15.1202C10.9772 15.1202 10.5506 15.5501 10.5506 16.0788C10.5506 16.6065 10.9772 17.0365 11.5013 17.0365C12.0254 17.0365 12.4515 16.6065 12.4515 16.0788C12.4515 15.5501 12.0254 15.1202 11.5013 15.1202Z' fill='#F4F4F4')
      template(#main)
        p.m-0.text-center El número de celular no puede exceder los diez caracteres
        b-button(
          variant="bluecolmedica"
          @click="showErrorExced = false"
        ).mt-3.mb-4.ButtonContinue.mx-auto.d-block
          span.mb-0.ButtonContinue__text.px-4
            | Ingresa nuevos datos
    Modal(v-if="showModalErrorNumber" :hideHeader="false")
      template(#header)
        svg(width='23' height='24' viewbox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M0.85 12.2422C0.85 6.36051 5.61792 1.59244 11.4995 1.59219C17.3791 1.59871 22.1438 6.36355 22.15 12.2431C22.1495 18.1245 17.3815 22.8922 11.5 22.8922C5.61817 22.8922 0.85 18.124 0.85 12.2422Z' stroke='#F4F4F4' stroke-width='1.7')
          path(d='M11.5013 7.45312C10.9558 7.45312 10.543 7.74005 10.543 8.20351V13.4127C10.543 13.8766 10.9557 14.1626 11.5013 14.1626C12.0335 14.1626 12.4596 13.8646 12.4596 13.4127V8.20351C12.4596 7.75106 12.0335 7.45312 11.5013 7.45312ZM11.5013 15.1202C10.9772 15.1202 10.5506 15.5501 10.5506 16.0788C10.5506 16.6065 10.9772 17.0365 11.5013 17.0365C12.0254 17.0365 12.4515 16.6065 12.4515 16.0788C12.4515 15.5501 12.0254 15.1202 11.5013 15.1202Z' fill='#F4F4F4')
      template(#main)
        p.m-0.text-center El primer número debe ser un tres
        b-button(
          variant="bluecolmedica"
          @click="cleanModalNumber"
        ).mt-3.mb-4.ButtonContinue.mx-auto.d-block
          span.mb-0.ButtonContinue__text.px-4
            | Ingresa nuevos datos
    Modal(
      v-if="showUserInactive || showUserOlder || showConfirmLink"
      :hideHeader="true"
    )
      template(#header)
        svg(width='23' height='24' viewbox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M0.85 12.2422C0.85 6.36051 5.61792 1.59244 11.4995 1.59219C17.3791 1.59871 22.1438 6.36355 22.15 12.2431C22.1495 18.1245 17.3815 22.8922 11.5 22.8922C5.61817 22.8922 0.85 18.124 0.85 12.2422Z' stroke='#F4F4F4' stroke-width='1.7')
          path(d='M11.5013 7.45312C10.9558 7.45312 10.543 7.74005 10.543 8.20351V13.4127C10.543 13.8766 10.9557 14.1626 11.5013 14.1626C12.0335 14.1626 12.4596 13.8646 12.4596 13.4127V8.20351C12.4596 7.75106 12.0335 7.45312 11.5013 7.45312ZM11.5013 15.1202C10.9772 15.1202 10.5506 15.5501 10.5506 16.0788C10.5506 16.6065 10.9772 17.0365 11.5013 17.0365C12.0254 17.0365 12.4515 16.6065 12.4515 16.0788C12.4515 15.5501 12.0254 15.1202 11.5013 15.1202Z' fill='#F4F4F4')
        p.mb-0.ml-2(v-if="showUserInactive") Usuario no activo
        //- p.mb-0.ml-2(v-else) Usuario menor de {{env.VUE_APP_OLD_AGE}} años
      template(#main)
        .containerCloseLink(v-if="showConfirmLink" @click="cleanModal")
          svg(width='14' height='14' viewbox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M1.41406 12.4297L11.8694 2.1381' stroke='#333333' stroke-width='2.61364' stroke-linecap='round' stroke-linejoin='round')
            path(d='M1.41406 2.30078L11.8694 12.5924' stroke='#333333' stroke-width='2.61364' stroke-linecap='round' stroke-linejoin='round')
        p.m-0.text-center(v-if="showUserInactive") Asegurate de ingresar los datos correctos del usuario que requiere autorización
        //- iframe.m-0.text-center.iframePageModal(
        //-   v-else-if="showConfirmLink"
        //-   src="https://www.colmedica.com/Paginas/Proteccion-datos.aspx"
        //- )
        div(v-else-if="showConfirmLink")
          p.text-center Haz clic para ver la política de tratamiento de datos personales
          b-button(
            variant="bluecolmedica"
            @click="goToNewWindow"
          ).mt-3.mb-4.ButtonContinue.mx-auto.d-block
            span.mb-0.ButtonContinue__text.px-4
              | Ver política
          p.text-center Vuelve a esta ventana cuando termines de ver la política de tratamiento de datos personales
        p.m-0.text-center(v-else) Este servicio es para mayores de {{env.VUE_APP_OLD_AGE}} años
        b-button(
          variant="bluecolmedica"
          @click="cleanModal"
          v-if="!showConfirmLink && counterShowedUserOlder !== 2"
        ).mt-3.mb-4.ButtonContinue.mx-auto.d-block
          span.mb-0.ButtonContinue__text.px-4
            | Ingresa nuevos datos
        b-button(
          variant="bluecolmedica"
          @click="goToPage"
          v-if="counterShowedUserOlder === 2"
        ).mt-3.mb-4.ButtonContinue.mx-auto.d-block
          span.mb-0.ButtonContinue__text.px-4
            | Ir a página web
        button.button-without-styles
    .ContainerForm.d-flex.justify-content-center.pr-5
      form(
        @submit.prevent="onValidate"
      ).contentForm.pt-3.mr-5
        p(
          :class="selected && 'activeLabel'"
        ).Autenticate_TextSection.weight-500.text-start.mb-2 Selecciona tipo de identificación
        .position-relative.mb-4#cc_number_container.custom-select-oa
          select.number-input.py-3.pl-4.position-relative(
            v-model="selected"
            size="sm"
            required
            autocomplete="off").position-relative.selectStyles
            option(v-for="option in options" :value="option.value")
              | {{ option.text }}
          .position-absolute.arrowBottomStyles
            svg(width='16' height='9' viewbox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg')
              path(d='M8.5126 8.06871L8.51246 8.06883C8.39388 8.17361 8.23941 8.23001 8.08116 8.22955M8.5126 8.06871L15.5486 0.994141C15.49 0.935725 15.4203 0.889527 15.3439 0.857661C15.2675 0.8258 15.1855 0.808735 15.1027 0.807163C15.0199 0.805592 14.9373 0.819535 14.8597 0.848425C14.7828 0.877076 14.7119 0.920017 14.6513 0.975338L8.08132 6.79031L1.51263 0.97678C1.45297 0.920037 1.38258 0.875597 1.30584 0.845474C1.22763 0.814776 1.14403 0.799409 1.05997 0.800017C0.975898 0.800626 0.892535 0.817202 0.814792 0.849049C0.737035 0.880902 0.666104 0.927549 0.606618 0.986795C0.547104 1.04607 0.500251 1.11678 0.469584 1.195C0.438902 1.27326 0.425244 1.35697 0.429745 1.44094C0.434246 1.52489 0.456771 1.60667 0.495476 1.68126C0.533464 1.75447 0.586096 1.81899 0.649669 1.8715L7.64949 8.06766C7.64957 8.06773 7.64964 8.06779 7.64971 8.06786C7.76819 8.173 7.92273 8.22975 8.08116 8.22955M8.5126 8.06871L15.5142 1.87096C15.5765 1.81752 15.6277 1.75238 15.6642 1.67887C15.701 1.60471 15.722 1.52384 15.7255 1.44107L15.5256 1.43273L8.5126 8.06871ZM8.08116 8.22955C8.08096 8.22955 8.08076 8.22955 8.08057 8.22955L8.08132 8.02955L8.08175 8.22955C8.08155 8.22955 8.08136 8.22955 8.08116 8.22955Z' fill='#66707E' stroke='#66707E' stroke-width='0.4')
        p(
          :class="number_id && 'activeLabel'"
        ).Autenticate_TextSection.weight-500.text-start.mb-2.mr-5 Ingrese el número de identificación del usuario que requiere atención
        .position-relative#id_number_container
          input.py-3.pl-4.number-input.mb-4#id_number(
            autocomplete="off"
            v-model="number_id"
            :disabled="!isCapcha"
            :placeholder="_number"
            ref="id_number"
            required)
        p(
          :class="phone_number && 'activeLabel'"
        ).Autenticate_TextSection.weight-500.text-start.mb-2 Ingresa tu número de celular
        .position-relative#phone_number_container
          input.py-3.pl-4.number-input.mb-4#phone_number(
            autocomplete="off"
            ref="phone_number"
            v-model="phone_number"
            type="text"
            max="1"
            required)
        .pt-3.mt-3.position-relative#checkbox-1_container
          p.AcceptTerms
            | Al dar clic en Continuar, acepto que mis datos serán tratados bajo la política de
            a(@click="() => changeConfirmLink(true)").ml-1 tratamiento de datos personales
        .d-flex.justify-content-center.pb-3
          .captcha.text-center
            div#captchaElement
        .d-flex.justify-content-center.pb-3
          b-button(
            variant="bluecolmedica"
            type="submit"
            :disabled="!number_id || !selected || phone_number.length < 10 || charging || !captchaEnable"
          ).mt-2.ButtonContinue
            div(v-if="!charging")
              span.mb-0.ButtonContinue__text.px-4 Continuar
            .py-1.px-4(v-else)
              MiniSpinner

    .Container_Figure
      figure.ContainerImage
        .ContainerImage__tap
        img(src="@/assets/older_adults/oldPeople.png" alt="oldPeople").oldPeople
        img(src="@/assets/older_adults/oldPeopleResponsive.png" alt="oldPeopleResponsive").oldPeopleResponsive
</template>

<script>
import { mapActions, mapState } from "vuex";

import MiniSpinner from "../components/MiniSpinner";
import Modal from "./components/Modal";
import { startSelect } from "./assets/custom-select";

export default {
  name: "Autenticate",

  data: () => ({
    selected: "CC",
    number_id: "",
    phone_number: "",
    isCapcha: true,
    loading: false,
    showConfirmLink: false,
    counterShowedUserOlder: 0,
    showModalErrorNumber: false,
    showErrorExced: false,
    captchaEnable: false,
    widgetId1: null
    // showUserInactive: false,
    // showUserOlder: false
  }),

  components: {
    MiniSpinner,
    Modal
  },

  mounted() {
    startSelect();

    /* eslint-disable */
    this.widgetId1 = grecaptcha.render('captchaElement', {
      'sitekey' : '6LcME6EhAAAAAN1bIw7wCcCXkY5uhPZpXMgppDb5',
      'callback' : this.verifyCallback,
      'error-callback': this.errorCallback,
      'expired-callback': this.errorCallback,
      'theme' : 'light'
    });
    console.log(this.widgetId1)
    /* eslint-enab|le */
  },

  watch: {
    showUserOlder(val) {
      if (val) {
        this.counterShowedUserOlder += 1;
      }
    },

    phone_number(val, oldVal) {
      this.phone_number = this.phone_number.trim();

      if (val) {
        if (+val[0] !== 3 && val[0]?.length === 1) {
          this.changeErrorNumber(true);
          return;
        }

        if (val.length > 10) {
          this.phone_number = oldVal;
          this.showErrorExced = true;
        }
      }
    }
  },

  computed: {
    ...mapState({
      env: state => state.env,
      options: state => state.olderAdult.tipide,
      branch_id: state => state.branchInfo,
      showUserInactive: state => state.olderAdult.showUserInactive,
      showUserOlder: state => state.olderAdult.showUserOlder,
      client: state => state.olderAdult.client,
      charging: state => state.olderAdult.charging
    }),

    _number() {
      let number_id = this.number_id;
      if (number_id !== "No. Identificación") {
        var convertedPhone = "******" + number_id.substring(6);
        if (number_id.length <= 6) {
          return number_id.replace(/\d/g, "*");
        }
        return convertedPhone;
      }
      return number_id || "2389043";
    },

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    modalStylesLink() {
      return `
        width: 100%;
        max-width: 900px;
        height: 80%;
      `;
    }
  },

  methods: {
    ...mapActions({
      goToView: "olderAdult/goToView",
      demoValidation: "autoservice/demoValidation",
      updateB64Final: "autoservice/updateB64Final",
      setPhoneIn: "olderAdult/setPhoneIn",
      authAndQueryColmedica: "olderAdult/authAndQueryColmedica",
      setCleanModals: "olderAdult/setCleanModals",
      setEnv: "olderAdult/setEnv"
    }),

    changeErrorNumber(val) {
      this.showModalErrorNumber = val;
    },

    cleanModalNumber() {
      this.changeErrorNumber(false);
      this.phone_number = "";
      this.$refs.phone_number.focus();
    },

    goToPage() {
      window.open("https://www.colmedica.com/Paginas/login.aspx", "_self");
    },

    cleanModal() {
      // this.showUserOlder = false;
      // this.showUserInactive = false;
      this.changeConfirmLink(false);
      this.setCleanModals();
    },

    goToNewWindow() {
      window.open("https://www.colmedica.com/Paginas/Proteccion-datos.aspx", "tab", "popup");
    },

    changeConfirmLink(value) {
      this.showConfirmLink = value;
    },

    async onValidate() {
      // this.charging = true;
      if (this.phone_number.length !== 10) return;
      if (!this.selected) return;

      // set phone
      this.setPhoneIn(this.phone_number);

      // set env to older man
      this.setEnv(this.env);

      // select option
      let doc_div = document.getElementById('cc_number');
      this.selected = this.options.find(o=>o.text == doc_div.textContent)?.value;

      let toSend = {
        ti: this.selected,
        nu: this.number_id,
        ce: this.phone_number
      };
      this.updateB64Final(toSend);

      this.loading = true;
      try {
        // if (this.demoMode) {
        //   await this.demoValidation(this.number_id);
        //   this.goToView({ view: "Atention" });
        //   return;
        // }
        return this.authAndQueryColmedica(toSend);
      } catch (error) {
        console.log("ERROR CATCH??")
        console.error(error);
        this.showError = true;
      }
      this.loading = false;
      
      /* eslint-disable */
      // validation
      grecaptcha.reset(this.widgetId1);
      /* eslint-enab|le */

    },

    verifyCallback(response){
      console.log(response)
      this.captchaEnable = true;
    },
    errorCallback(e){
      console.log("Error captcha ")
      console.log(e)
      this.captchaEnable = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.Autenticate__TextMain {
  font-size: 1.5rem;
}

.Autenticate__content {
  margin: auto;
  max-width: 1272px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(calc(100vh - 81px), 100%);
}

.Autenticate_TextSection {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #66707e;
}

.oldPeopleResponsive {
  display: none;
}

.number-input {
  display: block;
  text-align: start;
  padding: 2rem;

  width: 100%;
  height: auto;

  font-weight: 400;
  font-size: 24px;
  line-height: 32px;

  background-color: var(--color-white);
  color: var(--color-dark);

  border: 1px solid var(--color-gray);
  box-sizing: border-box;
  border-radius: 10px;
}

.number-input:focus {
  outline: 0.5px solid #3290e0;
}

.arrowBottomStyles {
  transition: 0.5s transform;
  right: 5px;
  padding-right: 15px;
  top: 50%;
  z-index: -2;
  transform: translateY(-50%);
  background-color: var(--color-white);
}

.arrowBottomStyles svg path {
  transition: 0.5s all;
}

.selected-arrow {
  transform: translateY(-50%) rotate(-180deg) translateX(15px);
}

.selected-arrow svg path {
  fill: #3290e0;
  stroke: #3290e0;
}

.activeLabel {
  color: #009fe3;
}

.activeLabel + div input:focus {
  outline: 0.5px solid #3290e0;
}

.contentForm {
  max-width: 465px;
  min-width: 320px;
  margin: 0 auto;
}

.ContainerImage {
  position: relative;
  margin: 0;
  height: 100%;
  max-width: 671px;
}

.ContainerImage__tap {
  background: rgba(199, 209, 229, 0.29);
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.oldPeople {
  height: 100%;
  width: 100%;

  position: relative;
  z-index: -1;

  object-fit: cover;
  object-position: 0 -60px;

  background-color: black;
}

.AcceptTerms {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-align: justify;
}

.ButtonContinue:disabled {
  background-color: #66707e;
}

.ContainerForm {
  grid-column: 1;
  padding-left: 6rem;
}

.Container_Figure {
  height: 100%;
  grid-column: 2;
}

.iframePageModal {
  width: 100%;
  height: 100%;
  border: none;
}

.containerCloseLink {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

.phone_number {
  border: none;
  outline: none !important;
  background: transparent;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  width: 211px;
  margin-right: 0rem;
  letter-spacing: 10px;
}

span.phone_number {
  background-image: none;
  width: 17px;
}

@media screen and (max-width: 780px) {
  .Container_Figure {
    grid-column: 1;
    grid-row: 1;
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    height: 100%;
  }

  .ContainerForm {
    grid-column: 1;
    grid-row: 2;
  }

  .Autenticate {
    padding: 0 !important;
  }

  .Autenticate__article {
    margin: 0 2rem;
    padding-top: 3rem;
  }

  .Autenticate__content {
    grid-template-columns: 1fr;
    grid-template-rows: 408px 1fr;
    justify-items: center;
  }

  .contentForm {
    margin: 0 !important;
  }

  .oldPeople {
    display: none;
  }

  .oldPeopleResponsive {
    display: block;
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: 0 -63px;
    background-color: black;
  }

  .captcha {
    width: 100%;
    text-align: center;
  }

  .ContainerImage {
    max-width: 100%;
  }
}

@media screen and (max-width: 1240px) {
  .ContainerForm {
    padding-left: 5rem;
  }
}

@media screen and (max-width: 1180px) {
  .ContainerForm {
    padding-left: 3rem;
  }
}
</style>
