<template lang="pug">
.Atention.position-relative
  .ImageContainerTap
  .mx-5
    .position-relative.Atention__Container
      .d-flex.align-items-center.py-3
        a.active-link(@click="onGoToHome") Home
        span.mx-2
          img(src="@/assets/older_adults/arrowRight.svg" alt="arrowRight").arrowRight
        a.active-link: strong.TextAtention atención preferencial adulto mayor
      p.Atention__MainText
        span.red 
          b ¡
        span.blue
          b Pensando en agilizar tu atención, te sugerimos adjuntar
        br
        span.blue
          b los documentos antes de iniciar tu videollamada
        span.red
          b !
        br
      .d-flex.justify-content-center.align-items-stretch.ContainerCards
        .position-relative.max-button.w-100.mr-3
          .tap#authorizations-container
          button.button-without-styles.Card.p-4#authorizations(@click="goUpload")
            h4.Card__Text.mt-3 
              svg(width='70' height='70' viewbox='0 0 70 70' fill='none' xmlns='http://www.w3.org/2000/svg')
                path(d='M28.1138 5L28.1138 51' stroke='white' stroke-width='10' stroke-linecap='round')
                path(d='M5 28H51' stroke='white' stroke-width='10' stroke-linecap='round')
              p.m-0.mt-3 Adjuntar
              p.m-0 Documentos
        .position-relative.max-button.w-100.mr-3
          .tap#service_client-container(v-show="isModeTutorial")
          button.button-without-styles.Card.p-4#service_client(@click="getTurnWaiting(env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH == 16 ? 779 : 22, 1)")
            img(src="@/assets/older_adults/IconAuths.svg" alt="IconAuths").IconAuths
            h4.Card__Text.mt-3 Prefiero hacerlo con el asesor

        //- .position-relative.max-button.w-100
        //-   .tap#service_client-container(v-show="isModeTutorial")
        //-   button.button-without-styles.Card.p-4#service_client(@click="getTurnWaiting(env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH == 16 ? 780 : 23, 2)")
        //-     img(src="@/assets/older_adults/IconServiceClient.svg" alt="IconServiceClient").IconServiceClient
        //-     h4.Card__Text.mb-0.mt-3 Servicio al
        //-     h4.Card__Text.mb-0 cliente
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import { getOneUseToken } from "../../helpers/Meet";
import { utf8_to_b64 } from "../../utils/decoder";

export default {
  name: "Atention",
  computed: {
    ...mapState({
      env: state => state.env,
      client: state => state.olderAdult.client,
      b64final: state => state.autoservice.b64final,
      service: state => state.autoservice.service,
      isModeTutorial: state => state.olderAdult.isModeTutorial
    })
  },
  methods: {
    ...mapActions({
      goToView: "olderAdult/goToView",
      updateB64Final: "autoservice/updateB64Final"
    }),
    ...mapMutations({
      setState: "olderAdult/setState"
    }),

    onGoToHome() {
      this.goToView({ view: "Home" });
    },

    async getTurnWaiting(service, option) {
      this.setState({
        key: "isOlderAdult",
        value: true
      });
      let to = await getOneUseToken();

      let fu = this.$route.path.substring(1);
      let su = Number(this.env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH);
      let tr = Number(service);

      let ma = this.client;
      let na = this.client?.Nombres;
      let ap =
        this.client?.PrimerApellido + " " + (this.client?.SegundoApellido || "");

      let jsonFinal = { to, tr, su, fu, ma, na, ap };
      this.updateB64Final(jsonFinal);

      let jsonB64 = JSON.stringify({
        ...this.b64final,
        sv: 'Autorizaciones Médicas',
        to
      });
      this.loading = false;

      // Check ControlMedicamentos for popup
      if(this.client?.ControlMedicamentos && option == 1) {
        localStorage.setItem("jsonB64", jsonB64);
        this.goToView({ view: "Popup" });
      } else {
        this.$router.push({
          name: "DecoderOlderAdults",
          query: { pr: utf8_to_b64(jsonB64) }
        });
      }
    },

    goUpload(){
      this.goToView({ view: "UploadFiles" });
    }
  }
};
</script>

<style lang="scss" scoped>
.Atention {
  display: block;
  height: calc(100vh - 70.98px);

  background-image: url(../../assets/older_adults/oldPeople2.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.ImageContainerTap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
}

.Atention__Container {
  max-width: 1080px;
  width: 100%;
  margin: auto;
}

.Atention__MainText {
  font-weight: 400;
  font-size: 30px;
  line-height: 32px;
  text-align: center;
  padding-bottom: 25px;
  padding-top: 25px;
}

.TextAtention {
  color: var(--color-primary);
}

.ContainerCards {
  height: 264px;
  z-index: 0;
}

.Card {
  max-width: 252px;
  width: 100%;
  height: 264px;
  z-index: 2;
  position: relative;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  border-radius: 24px;

  background-color: #00aeee;
  color: white;
}

.max-button {
  max-width: 252px;
  // height: 360px;
}

.Card__Text {
  font-weight: 700;
  font-size: 25px;
  color: white;
}

.active-link {
  font-size: 18px;
  line-height: 17px;

  cursor: pointer;
  text-decoration: none;
  color: var(--color-dark);
}

.tap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

@media screen and (max-width: 780px) {
  .Atention {
    background-position: center;
  }
}


.blue {
  color: rgb(5, 169, 221);
}

.red {
  color: #ff0304;
}
// 1344
</style>
