<template lang="pug">
.Content
  .LoadingColmedica
    .SvgLogo.mt-5
      svg(width='88' height='79' viewbox='0 0 88 79' fill='none' xmlns='http://www.w3.org/2000/svg')
        path(d='M65.732 47.626C65.732 47.4364 65.672 47.3101 65.3719 47.1742C65.0244 47.0416 64.2 46.7162 64.1084 46.6815C63.9813 46.6268 63.8454 46.5958 63.7072 46.5898C63.6471 46.5924 63.5876 46.603 63.5303 46.6214L53.823 49.8088C50.4714 50.8607 46.5954 53.8269 45.4804 58.0914L40.1102 78.3526C40.0764 78.4804 40.05 78.6102 40.0312 78.7411L40.1007 78.719L40.1513 78.7032C40.5146 78.6274 41.816 77.3386 43.5945 75.0199C45.3729 72.7013 46.6018 70.6922 46.7976 69.7698C46.8576 69.4224 46.9113 68.8411 46.9935 68.0356V68.0198C47.164 66.2982 47.4168 63.6953 47.9854 60.2015C48.6171 56.3572 51.0274 53.5015 54.9728 51.9537L55.0139 51.9379C56.129 51.5177 61.8498 49.3697 65.2266 48.1282L65.3435 48.084C65.4515 48.0649 65.5494 48.0086 65.6203 47.925C65.6913 47.8414 65.7308 47.7356 65.732 47.626V47.626Z' fill='#F4F4F4')
        path(d='M86.9151 25.3626C87.0353 25.3241 87.1356 25.24 87.1946 25.1284C87.2537 25.0169 87.2667 24.8866 87.231 24.7656C86.8429 23.4005 86.1718 22.1325 85.2612 21.044C84.3507 19.9555 83.2212 19.0709 81.9461 18.4478L81.6997 18.3277L81.6618 18.3088C80.7963 17.9276 79.8593 17.7359 78.9136 17.7465C78.6651 17.7465 78.4124 17.7591 78.1555 17.7844C75.5934 18.1017 73.1427 19.0207 71.0037 20.4663C70.7794 20.6116 70.6278 20.7285 70.4193 20.8865C70.274 21.0002 70.1034 21.1265 69.8665 21.2971C65.3209 24.6108 61.8903 29.4281 60.6994 34.1665C59.8749 37.4296 60.2129 40.4306 61.647 42.6228C62.1928 43.4568 62.908 44.1666 63.7462 44.7059C64.5843 45.2452 65.5266 45.6021 66.5118 45.7533C66.558 45.7581 66.6045 45.7581 66.6508 45.7533C66.7672 45.7533 66.8789 45.7071 66.9612 45.6247C67.0436 45.5424 67.0898 45.4307 67.0898 45.3142C67.0896 45.2435 67.0719 45.1738 67.0383 45.1116C67.0047 45.0493 66.9562 44.9963 66.8971 44.9573L66.8782 44.9415C64.7017 43.182 64.1994 38.7374 65.5673 33.3388C66.5149 29.5166 68.2776 26.1397 70.6215 23.5715C72.9654 21.0033 75.7389 19.4302 78.6388 19.0164C79.298 18.9183 79.9674 18.9098 80.6289 18.9911C82.7674 19.1807 85.4209 21.9921 86.3149 25.012C86.3442 25.1115 86.405 25.1988 86.4882 25.2609C86.5713 25.323 86.6723 25.3564 86.7761 25.3563C86.822 25.3635 86.8686 25.3656 86.9151 25.3626Z' fill='#F4F4F4')
        path(d='M20.7756 75.6394C22.355 73.5988 23.4196 71.8203 23.5965 70.999C23.647 70.6831 23.6975 70.1808 23.767 69.4732C23.9155 67.9759 24.1366 65.711 24.6294 62.6721C25.1633 59.3806 27.2355 56.9387 30.6313 55.612C30.8682 55.5235 36.3869 53.4481 39.5679 52.2762L39.6279 52.2509C39.7573 52.226 39.874 52.157 39.9581 52.0556C40.0422 51.9542 40.0885 51.8267 40.0891 51.695C40.0912 51.5726 40.0518 51.4532 39.9771 51.3562C39.9025 51.2592 39.7972 51.1904 39.6784 51.1611C39.4447 51.0663 38.6581 50.7599 38.5886 50.7283C38.4583 50.6735 38.3192 50.6424 38.1779 50.6367C38.1093 50.6395 38.0414 50.6512 37.9758 50.6715L29.5068 53.4323C26.55 54.3579 23.1226 56.9829 22.1402 60.7547L17.465 78.3971C17.4086 78.5824 17.3809 78.7752 17.3829 78.9689C17.4792 78.9595 17.5738 78.9372 17.6641 78.9025H17.683C18.1632 78.8046 19.5815 77.1904 20.7756 75.6394Z' fill='#F4F4F4')
        path(d='M51.1747 26.9395C51.7366 26.8561 52.3073 26.8497 52.871 26.9205C54.6747 27.0753 56.927 29.4698 57.6851 32.0506C57.72 32.1701 57.7928 32.2751 57.8925 32.3497C57.9922 32.4244 58.1134 32.4646 58.2379 32.4644C58.2924 32.465 58.3467 32.4576 58.3991 32.4423C58.546 32.3991 58.6699 32.2993 58.7433 32.1648C58.8167 32.0304 58.8338 31.8722 58.7908 31.7252C58.4455 30.5149 57.8502 29.3905 57.0433 28.4247C56.2363 27.4589 55.2357 26.6731 54.1061 26.1182L53.8913 26.0139L53.8566 25.995C53.0819 25.6528 52.2426 25.4815 51.3958 25.4927C51.1747 25.4927 50.9504 25.4927 50.7229 25.5243C48.4604 25.8017 46.2956 26.6103 44.4051 27.884C44.2061 28.0167 44.0892 28.1177 43.8902 28.2567L43.4132 28.6169C39.4141 31.5325 36.4036 35.7623 35.358 39.932C34.6283 42.8161 34.9316 45.4759 36.2078 47.4186C36.6959 48.1635 37.3351 48.7975 38.0838 49.2797C38.8326 49.7618 39.6743 50.0813 40.5544 50.2174C40.6028 50.2211 40.6514 50.2211 40.6998 50.2174C40.8162 50.2209 40.9306 50.1865 41.0257 50.1193C41.1209 50.0522 41.1916 49.9559 41.2272 49.845C41.2629 49.7341 41.2615 49.6147 41.2234 49.5046C41.1852 49.3946 41.1123 49.3 41.0156 49.235C39.1614 47.7377 38.7444 43.9249 39.9195 39.2845C40.6615 36.1552 42.1618 33.2565 44.2883 30.8439C46.3163 28.6484 48.6981 27.2933 51.1747 26.9395Z' fill='#F4F4F4')
        path(d='M29.6652 37.9628L28.0415 37.331C27.8798 37.2598 27.7063 37.2191 27.5298 37.2109C27.4535 37.2141 27.378 37.228 27.3055 37.252L14.7931 41.3586C10.4749 42.7137 2.83985 46.536 1.40571 52.0293L0 57.3742H6.85481C6.97169 56.5434 7.10436 55.6715 7.25599 54.7491C8.06151 49.7865 11.1793 46.1032 16.2746 44.1037H16.2968C17.1718 43.7878 24.9711 40.8468 29.4946 39.1821L29.6463 39.1252C29.7789 39.0971 29.898 39.0244 29.9836 38.9192C30.0692 38.8141 30.1163 38.6828 30.1169 38.5472C30.1169 38.3008 30.0411 38.1302 29.6652 37.9628Z' fill='#F4F4F4')
        path(d='M57.2598 9.84495C57.3188 9.84504 57.3775 9.83543 57.4335 9.81652C57.5875 9.7711 57.7173 9.66645 57.7943 9.52551C57.8712 9.38457 57.8892 9.21885 57.8442 9.0647C57.3443 7.30487 56.4799 5.66985 55.3072 4.26578C54.1344 2.86171 52.6794 1.71998 51.0367 0.914739L50.7208 0.759956L50.6703 0.734684C49.2523 0.120219 47.6989 -0.114918 46.1626 0.0523607C42.8578 0.460238 39.6962 1.64327 36.9354 3.50504C36.6511 3.69773 36.4616 3.84304 36.1867 4.04837C35.9972 4.19368 35.7793 4.36426 35.476 4.58222C29.6036 8.86569 25.1811 15.0792 23.6491 21.1917C22.5845 25.3994 23.0173 29.2627 24.8747 32.0836C25.5767 33.1585 26.4974 34.0733 27.5767 34.7684C28.6561 35.4635 29.8698 35.9232 31.1388 36.1175C31.1969 36.1302 31.2563 36.1366 31.3157 36.1365C31.4338 36.1368 31.5488 36.0994 31.644 36.0297C31.7393 35.96 31.8097 35.8616 31.845 35.749C31.8803 35.6364 31.8786 35.5154 31.8402 35.4038C31.8018 35.2922 31.7287 35.1958 31.6316 35.1288L31.5906 35.1161C28.7791 32.8449 28.1158 27.1052 29.8911 20.143C31.1357 15.2119 33.3911 10.8558 36.4174 7.54527C39.4436 4.23474 43.0163 2.20041 46.7596 1.66656C47.6104 1.54058 48.4744 1.53102 49.3278 1.63813C52.0918 1.87821 55.5192 5.50462 56.6659 9.40586C56.7026 9.53186 56.779 9.64265 56.8837 9.72176C56.9884 9.80086 57.1159 9.84407 57.2471 9.84495H57.2598Z' fill='#F4F4F4')
    .contain(v-if="isSending")
      div(class="text-middle")
        span.d-block.SenderText Estamos enviando
        span.d-block.SenderText tu información...
        span.d-block.SenderText.pt-5 Por favor no cierres esta ventana
      //- .mt-5.maxSizingProgress
      //-   Spinner
        //- img.maxSizingProgress.imageContentable(src="@/assets/spinner.gif")
        //- progress-bar(class="maxSizingProgress" type="circle" :ref="progress_sending" :options="options")
    .contain(v-else)
      .text-center.mt-3
        span.d-block.SenderText {{ files.length>1 ? 'Tus ' : 'Tu ' }}
          span(v-show="files.length > 1") {{ files.length }}
          span {{ files.length > 1 ? ' documentos ' : ' documento ' }}
          span {{ files.length > 1 ? 'han' : 'ha' }}
        span.SenderText {{ files.length > 1 ? ' sido enviados ' : ' sido enviado ' }}
        span.SenderText.ml-1 exitosamente
      .mt-1.buttons-action.px-5
        b-button(variant="bluecolmedica" class="text-center mt-5" @click="getTurnWaiting(env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH == 16 ? 779 : 22, 1)").w-100
          span(class="text-sizing-12") Continuar
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { utf8_to_b64 } from "@/utils/decoder";
import { getOneUseToken } from "@/helpers/Meet";
import Header from "./components/Header";
import _get from "lodash/get";
import Spinner from "../components/Spinner";

export default {
  name: "ConfirmUpload",
  components: {
    Header,
    Spinner
  },
  data: () => ({
    isSending: true,
    options: {
      color: "rgb(92, 147, 194)",
      strokeWidth: 8,
      trailColor: "rgb(205, 222, 236)",
      trailWidth: 8,
      fill: "rgb(0, 87, 160)"
    },
    progress_sending: uuidv4(),
    state_progress: null,
    timingController: 3
  }),
  mounted() {
    console.log("estoy en el componente")
    this.sendFiles();
  },
  computed: {
    ...mapState({
      files: state => state.olderAdult.files,
      client: state => state.olderAdult.client,
      b64final: state => state.olderAdult.b64final,
      service: state => state.olderAdult.service,
      HorarioOutContinue: state => state.olderAdult.HorarioOutContinue,
      env: state => state.env,
      phone_in: state => state.olderAdult.phone_in
    }),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    name() {
      let pureName = (_get(this.client, "Nombres") || "").split(" ");
      let pureLastName1 = _get(this.client, "PrimerApellido") || "";
      let pureLastName2 = _get(this.client, "SegundoApellido") || "";
      return [...pureName, pureLastName1, pureLastName2]
        .filter(data => !!data)
        .join(" ");
    }
  },
  methods: {
    ...mapActions({
      goToView: "olderAdult/goToView",
      updateB64Final: "olderAdult/updateB64Final",
      updateB64FinalAuto: "autoservice/updateB64Final",
      guardarSolicitudColmedica: "olderAdult/guardarSolicitudColmedica",
      guardarSolicitudDemo: "olderAdult/guardarSolicitudDemo",
      putLog: "log/put"
    }),
    ...mapMutations({
      setState: "olderAdult/setState"
    }),
    endTiming() {
      if (this.state_progress) this.state_progress.cancel();
      this.isSending = false;
    },
    async requestVideoCall() {
      if (this.HorarioOutContinue) {
        this.goToView("OutOperationsFinish");
      } else {
        this.goToView("IndexSchedules");
      }
    },
    async sendFiles() {
      // this.state_progress = progressUpdated(
      //   this.$refs[this.progress_sending],
      //   this.timingController,
      //   this.endTiming
      // );
      let code;
      try {
        if (this.demoMode) code = await this.guardarSolicitudDemo();
        else code = await this.guardarSolicitudColmedica();
        console.log("Numero solicitud: ")
        console.log(code)
        this.updateB64Final({
          so: code,
          na: this.name
        });
        this.putLog({
          name: "ConfirmUpload. Archivos subidos a la plataforma."
        });
      } catch (error) {
        this.updateB64Final({
          so: 10987654321
        });
        this.putLog({
          name: "ConfirmUpload. No se subieron los archivos a la plataforma.",
          checked: false,
          message: error
        });
        console.error("Invalid request of documents.", error);
      }
      this.isSending = false;
    },

    /**
     * Send request for a turn
     * @param {*} service 
     * @param {*} option 
     */
    async getTurnWaiting(service, option) {
      this.setState({
        key: "isOlderAdult",
        value: true
      });
      let to = await getOneUseToken();

      let fu = this.$route.path.substring(1);
      let su = Number(this.env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH);
      let tr = Number(service);
      let nu = this.client.NumeroIdentificacion;
      let ti = this.client.TipoIdentificacion;
      let ce = this.phone_in;

      console.log(this.client)

      let ma = this.client.ma;
      let na = this.client?.Nombres;
      let ap =
        this.client?.PrimerApellido + " " + (this.client?.SegundoApellido || "");

      let jsonFinal = { to, tr, su, fu, ma, na, ap, nu, ti, ce };

      // update all B64 data
      this.updateB64Final(jsonFinal);
      this.updateB64FinalAuto(jsonFinal);

      let jsonB64 = JSON.stringify({
        ...this.b64final,
        sv: this.service,
        to
      });
      this.loading = false;

      // Check ControlMedicamentos for popup
      if(this.client?.ControlMedicamentos && option == 1) {
        localStorage.setItem("jsonB64", jsonB64);
        this.goToView({ view: "Popup" });
      } else {
        this.$router.push({
          name: "DecoderOlderAdults",
          query: { pr: utf8_to_b64(jsonB64) }
        });
      }
    },

  },
  destroyed() {
    this.progress_sending = uuidv4();
    this.state_progress = null;
  }
};
</script>

<style scoped>
.contain {
  margin-top: 40px;
  position: relative;
  width: 100%;
}
.text-middle {
  margin-top: 25px;
  font-size: 15px;
  text-align: center;
  color: #00599d;
}
.maxSizingProgress {
  display: flex;
  justify-content: center;
  max-width: 130px;
  margin: 0px auto;
}
.sending-image {
  margin-top: 20px;
  width: 100px;
}
.imageContentable {
  object-fit: center;
  object-position: center;
}

.LoadingColmedica {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 11111111111111111111111111111111111111;
  justify-content: center;
  background-color: var(--color-primary);
}

.SenderText {
  font-size: 20px;
  color: var(--color-white);
}

.SvgLogo {
  box-shadow: 0px 4px 20px 15px #0160af;
  width: 172px;
  height: 172px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.buttons-action {
  position: fixed;
  bottom: 31.11px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 411px;
  width: 100%;
}
</style>
