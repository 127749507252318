<template lang="pug">
.Home.p-5
  .d-flex.align-items-start.py-5.Home__content
    article.Home__article
      h2.text-center.TitleSection.p-3 ¡Información!
      div(style="max-width: 750px; margin: 0 auto; width: 100%")
        p.mb-4.Home__TextMain.text-center En este canal sólo se autorizan servicios médicos cubiertos por tu plan de medicina prepagada. 
        p.Home__TextMain.mb-4.text-center
          | Para realizar la solicitud de medicamentos ambulatorios mediante Aliansalud EPS debes dirigirte a las
          strong.ml-1 oficinas de atención
          |, encuentra la más cercana en el botón 'Más información'
      .d-flex.items-center.containerBtns.justify-center
        b-button(
          variant="bluecolmedica"
          @click="onContinue"
        ).mt-2.ButtonContinue
          p.mb-0.ButtonContinue__text.px-4 Entendido
        a(href="https://www.aliansalud.com.co/Paginas/default.aspx" target="_blank" style="text-decoration: underline; font-size: 1.5rem;").pt-4.mb-0.mx-3.color-dark
          strong Más información
</template>

<script>
import { mapActions, mapState } from "vuex";
import { utf8_to_b64 } from "../../utils/decoder";

export default {
  name: "Home",

  computed: {
    ...mapState({
      env: state => state.env
    })
  },
  methods: {
    ...mapActions({
      goToView: "olderAdult/goToView"
    }),
    onContinue() {
      const jsonB64 = localStorage.getItem("jsonB64");
      this.$router.push({
        name: "DecoderOlderAdults",
        query: { pr: utf8_to_b64(jsonB64) }
      });
      // validation
    }
  }
};
</script>

<style lang="scss" scoped>
.Home__article {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: fit-content;
  margin: 0 auto;
}

.Home__TextMain {
  font-size: 1.5rem;
  text-align: justify;
}

.Home__TextMain strong {
  text-align: justify;
}

.Home__content {
  max-width: 720px;
  margin: 0 auto;
  display: block;
}

.containerBtns {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 780px) {
  .Home {
    padding: 0 !important;
  }

  .Home__article {
    margin: 0 2rem;
    padding-top: 3rem;
  }

  .Home__content {
    flex-wrap: wrap;
    padding-top: 0 !important;
  }

  .viewingPhone {
    width: 100%;
    max-height: 419px;

    object-fit: cover;
    object-position: 0 -50px;
  }

  .ButtonContinue {
    display: block;
    margin-top: 5rem !important;
    margin: auto;
  }
}
</style>
