<template lang="pug">
.Home.px-5.pb-5.pt-3
  .TitleHome 
    span.blue
      b Te damos la Bienvenida al
    br
    span.red 
      b ¡
    span.blue
      b Canal Preferencial Adulto Mayor
    span.red
      b !
  .d-flex.align-items-start.py-5.Home__content
    img(src="@/assets/older_adults/viewingPhone.png" alt="viewingPhone").viewingPhone
    article.pl-4.Home__article
      //- h2.text-center.TitleSection.p-3 Bienvenido
      p.mb-4.Home__TextMain Si eres mayor de 60 años te brindamos asesoría preferencial para tus autorizaciones médicas por videollamada.
      p.Home__TextMain.mb-4 Verifica que el audio, el micrófono y la cámara de tu dispositivo funcionen correctamente.
      p.Home__TextMain.mb-4
        b Horario de atención de videollamada es:
        br
        span Lunes a Viernes de 7:00 am a 6:30 pm
        br
        span Sábados de 7:00 am a 1:00 pm

      div(style="width:100%;text-align:center")
        b-button(
          variant="bluecolmedica"
          @click="onContinue"
        ).mt-2.ButtonContinue
          p.mb-0.ButtonContinue__text.px-4 Continuar
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Home",

  computed: {
    ...mapState({
      env: state => state.env
    })
  },
  methods: {
    ...mapActions({
      goToView: "olderAdult/goToView"
    }),
    onContinue() {
      this.goToView({ view: "Autenticate" });
      // validation
    }
  }
};
</script>

<style lang="scss" scoped>
.Home__TextMain {
  font-size: 1.5rem;
  text-align: center;
  line-height: 35px;
}

.Home__TextMain strong {
  text-align: center;
}

.Home__content {
  max-width: 1078px;
  margin: auto;
}

@media screen and (max-width: 780px) {
  .Home {
    padding: 0 !important;
  }

  .Home__article {
    margin: 0 2rem;
    padding-top: 3rem;
    text-align: center;
  }

  .Home__content {
    flex-wrap: wrap;
    padding-top: 0 !important;
  }

  .viewingPhone {
    width: 100%;
    max-height: 419px;

    object-fit: cover;
    object-position: 0 -50px;
  }

  .ButtonContinue {
    display: block;
    margin-top: 5rem !important;
    margin: auto;
  }
}

.TitleHome {
  width: 100%;
  font-size: 30px;
  text-align: center;
  line-height: 35px;
}

.blue {
  color: rgb(5, 169, 221);
}

.red {
  color: #ff0304;
}

</style>
